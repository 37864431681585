<template>
	<div
		class="component c-videos c-videos--slider position-relative"
		v-if="data && data.length"
	>
		<header
			class="c-videos__header border-b border-style-dotted display-flex align-items-center line-height-100 margin-b-12 padding-b-16 bp-1024:margin-b-24"
		>
			<div
				class="c-videos__header-title font-size-18 font-weight-700 line-height-100 margin-r-auto text-brand bp-1024:font-size-24"
			>
				Videos
			</div>
			<a
				href="https://www.youtube.com/user/ncaaresources"
				class="c-videos__header-more display-none font-size-16 font-weight-700 text-decoration-none hover:text-decoration-underline bp-1024:display-inline-block-block"
				target="_blank"
				>More Videos</a
			>
		</header>
		<swiper
			class="c-videos__slider swiper"
			ref="swiper_slider"
			:options="swiper_options"
			v-if="data && data.length"
		>
			<swiper-slide
				class="c-videos__item"
				v-for="(item, index) in data"
				:key="index"
			>
				<div
					class="c-videos__media aspect-ratio-16-9 position-relative overflow-hidden"
				>
					<button
						class="c-videos__play-btn reset position-absolute top-50 left-50 transform-translate-n50 z-index-3"
						@click="play_video(item.id)"
					></button>
					<img
						v-lazy="item.maxres ? item.maxres.url : item.medium.url"
						:alt="item.title"
						class="c-videos__image position-absolute top-0 left-0 width-100 height-100 object-position-c object-fit-cover transition"
					/>
				</div>
				<div class="c-videos__details padding-12">
					<div
						class="c-videos__title font-size-18 font-weight-700 line-height-150"
					>
						{{ item.title }}
					</div>
				</div>
			</swiper-slide>
		</swiper>
		<div
			class="videos-swiper-button-prev swiper-button-prev"
			slot="button-prev"
		>
			<arrow-left-icon size="24"></arrow-left-icon>
		</div>
		<div
			class="videos-swiper-button-next swiper-button-next"
			slot="button-next"
		>
			<arrow-right-icon size="24"></arrow-right-icon>
		</div>
		<modal-base v-model="modal_active">
			<template>
				<div style="width:100%;max-width:1024px;">
					<youtube
						fitParent
						:video-id="active_video_id"
						ref="youtube"
					></youtube>
				</div>
			</template>
		</modal-base>
	</div>
</template>

<script>
import { ArrowRightIcon, ArrowLeftIcon } from 'vue-feather-icons';
import ModalBase from '@/components/Common/ModalBase';

export default {
	name: 'YoutubeSlider',

	components: {
		ModalBase,
		ArrowRightIcon,
		ArrowLeftIcon,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
	},

	data: () => ({
		swiper_options: {
			spaceBetween: 24,
			touchReleaseOnEdges: true,
			navigation: {
				nextEl: '.videos-swiper-button-next',
				prevEl: '.videos-swiper-button-prev',
			},
			keyboard: {
				enabled: true,
				onlyInViewport: false,
			},
			breakpoints: {
				540: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
			},
		},
		modal_active: false,
		active_video_id: null,
	}),

	computed: {
		player() {
			return this.$refs.youtube.player;
		},
	},

	watch: {
		modal_active: function(val) {
			if (val === false) this.active_video_id = null;
		},
	},

	methods: {
		play_video(id) {
			this.active_video_id = id;
			this.modal_active = true;
			setTimeout(() => this.player.playVideo(), 250);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins';

.c-videos--slider {
	.swiper-container {
		padding-bottom: 48px;
	}

	.swiper-button-prev,
	.swiper-button-next {
		bottom: 0;
		top: auto;
		left: calc(50% - 48px);
		height: 48px;
		width: 48px;

		&::after {
			content: none;
		}
	}

	.swiper-button-next {
		margin-left: 48px;
	}
	.c-videos {
		//for naming
		&__item {
			&:hover,
			&:focus {
				.c-videos__image {
					transform: scale(1.1);
				}
				.c-videos__media::before {
					opacity: 0;
				}
			}
		}
		&__media {
			&::before {
				content: '';
				background: rgba($color: #000000, $alpha: 0.4);
				height: 100%;
				pointer-events: none;
				position: absolute;
				top: 0;
				left: 0;
				transition: 0.25s;
				width: 100%;
				z-index: 2;
			}
		}
		&__play-btn {
			@include play_button(75, 6, 48);
			position: absolute !important;
		}
		&__header-title{
			font-family: mislab-std, sans-serif;
		}
	}
}
</style>

<template>
	<section v-if="original_data && original_data.length">
		<h2 class="accessible-hide">{{ sectionTitle }}</h2>
		<component :is="current_breakpoint.template" :data="data" />
	</section>
</template>

<script>
import { get } from '@/helpers';
import baseComponent from '@/mixins/baseComponent';
import YoutubeFeatured from './templates/Youtube_Featured';
import YoutubeSlider from './templates/Youtube_Slider';
import YoutubeList from './templates/Youtube_List';

export default {
	name: 'Youtube',

	mixins: [baseComponent],

	components: {
		YoutubeFeatured,
		YoutubeSlider,
		YoutubeList,
	},

	methods: {
		get_data() {
			let request_options = {
				type: 'youtube',
				count: this.count,
				start: this.start,
			};

			if (this.sport_id && this.sport_id != 0) {
				request_options.sport_id = this.sport_id;
			}

			if (this.division_id && this.division_id != 0) {
				request_options.division_id = this.division_id;
			}

			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			)
				.then(response => {
					this.original_data = response;
					this.setup_breakpoints();
				})
				.catch(error => {
					console.log(error);
				});
		},
	},

	watch: {
		$route() {
			this.get_data();
		},
	},

	created() {
		this.get_data();
	},
};
</script>

<template>
	<div
		class="component c-videos c-videos--featured"
		v-if="data && data.length"
	>
		<h2 class="section-header__title margin-0 accessible-hide">Videos</h2>
		<header
			class="display-none c-videos__header margin-b-24 section-header width-100
				bp-1024:margin-b-48"
		>
			<h2 class="section-header__title margin-0">Videos</h2>
		</header>
		<div class="row">
			<div
				class="c-videos__player-container col-1-of-1 bp-1024:col-2-of-3"
				ref="video_player"
				v-show="selected_video"
			>
				<div
					class="c-videos__player position-relative aspect-ratio-16-9 overflow-hidden"
				>
					<youtube
						class="position-absolute top-0 left-0 height-100 width-100"
						fitParent
						:video-id="selected_video.id"
						ref="youtube"
					></youtube>
				</div>
				<div
					class="c-videos__player-details padding-y-12 bp-1024:padding-b-0 bp-1024:padding-t-24"
				>
					<div
						class="c-videos__player-title font-size-18 font-weight-bold padding-l-12 bp-1024:font-size-24"
					>
						{{ selected_video.title }}
					</div>
				</div>
			</div>
			<div
				class="c-videos__list-container col-1-of-1 bp-1024:padding-l-24 bp-1024:col-1-of-3"
			>
				<ul class="c-videos__list reset-list">
					<li
						class="c-videos__item display-flex align-items-center font-size-16 padding-y-12"
						v-for="(item, index) in data"
						:key="index"
						@click.prevent="play_video(item)"
					>
						<div class="c-videos__media-container">
							<div
								class="c-videos__media aspect-ratio-16-9 position-relative overflow-hidden"
							>
								<img
									v-lazy="
										item.maxres
											? item.maxres.url
											: item.medium.url
									"
									:alt="item.title"
									class="c-videos__media-image position-absolute top-0 left-0 width-100 transition"
								/>
								<button
									class="c-videos__play-btn reset position-absolute top-50 left-50 transform-translate-n50"
								></button>
							</div>
						</div>
						<div class="c-videos__details padding-l-12">
							<div class="c-videos__title">{{ item.title }}</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'YoutubeFeatured',

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},

		sectionTitle: {
			type: String,
		},
	},

	data: () => ({
		selected_video: null,
	}),

	computed: {
		player() {
			return this.$refs.youtube.player;
		},
	},

	methods: {
		play_video(video) {
			if (!video) return;
			this.selected_video = video;

			this.$nextTick(() => {
				let video_player_top = this.$refs.video_player.offsetTop;
				let header_height = parseInt(
					getComputedStyle(document.body)
						.getPropertyValue('--header-height')
						.replace('px', ''),
				);
				let sub_header_height = parseInt(
					getComputedStyle(document.body)
						.getPropertyValue('--sub-header-height')
						.replace('px', ''),
				);
				let full_header_height = isNaN(sub_header_height)
					? header_height
					: header_height + sub_header_height;

				window.scrollTo({
					top: video_player_top - full_header_height,
					behavior: 'smooth',
				});
				setTimeout(() => this.player.playVideo(), 250);
			});
		},
	},

	created() {
		if (this.data && this.data.length) {
			this.selected_video = this.data[0];
		}
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins';

.c-videos {
	&__player-title {
		border-left: 6px solid var(--color-primary);
		font-family: mislab-std, sans-serif;
	}
	&__item {
		border-bottom: 1px dotted var(--color-default-theme-stroke);

		&:hover,
		&:focus {
			cursor: pointer;

			.c-videos__media-image {
				transform: scale(1.1);
			}
		}
	}
	&__media-container {
		flex: 0 0 33.3333%;
	}
	&__play-btn {
		@include play_button;
		position: absolute !important;
	}
	&__details {
		flex: 1 1 66.6666%;
	}
	&__title {
		color: var(--color-primary);
	}
}
@media screen and (min-width: 1024px) {
	.c-videos {
		//for naming
		&__player-container {
			border-right: 1px dotted var(--color-default-theme-stroke);
		}
		&__item {
			&:first-child {
				padding-top: 0 !important;
			}
		}
	}
}
</style>

<template>
	<div
		class="c-modal position-fixed bottom-0 left-0 utility-flex-center width-100 transition transition-timing-function-ease z-index-100"
		:class="
			value
				? 'opacity-1 pointer-events-auto'
				: 'opacity-0 pointer-events-none'
		"
		style="background:rgba(0,0,0,0.95);"
		v-dom-portal
	>
		<button
			class="reset text-white position-absolute border-radius-rounded top-0 right-0 margin-r-24 margin-t-24 cursor-pointer transition transition-timing-function-ease
				hover:text-black hover:bg-color-white"
			style="width:48px;height:48px;"
			aria-label="Close modal"
			@click="close_modal()"
		>
			<x-icon size="24"></x-icon>
		</button>
		<slot></slot>
	</div>
</template>

<script>
import { XIcon } from 'vue-feather-icons';

export default {
	name: 'ModalBase',

	components: {
		XIcon,
	},

	props: ['value'],

	methods: {
		close_modal() {
			this.$emit('input', false);
		},
	},

	mounted: function() {
		document.addEventListener('keydown', e => {
			if (this.value && e.keyCode == 27) {
				this.$emit('input', false);
			}
		});
	},
};
</script>

<style lang="scss" scoped>
.c-modal {
	height: calc(100% - var(--full-header-height));
}
</style>

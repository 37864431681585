<template>
	<div class="component c-videos c-videos--list" v-if="data && data.length">
		<header
			class="c-videos__header border-b display-flex align-items-center line-height-100 margin-b-12 padding-b-16 bp-1024:margin-b-24"
		>
			<div
				class="c-videos__header-title font-size-18 font-weight-700 line-height-100 margin-r-auto text-brand bp-1024:font-size-24"
			>
				Videos
			</div>
			<a
				href="https://www.youtube.com/user/ncaaresources"
				class="c-videos__header-more display-none font-size-16 font-weight-700 text-decoration-none hover:text-decoration-underline bp-1024:display-inline-block"
				target="_blank"
				>More Videos</a
			>
		</header>
		<ul class="c-videos__list reset-list">
			<li
				class="c-videos__item margin-b-12"
				v-for="(item, index) in data"
				:key="index"
			>
				<div
					class="c-videos__media aspect-ratio-16-9 position-relative overflow-hidden"
				>
					<img
						v-lazy="item.maxres ? item.maxres.url : item.medium.url"
						:alt="item.title"
						class="c-videos__image position-absolute top-0 left-0 width-100 height-100 object-position-c object-fit-cover transition"
					/>
					<button
						class="c-videos__play-btn reset position-absolute top-50 left-50 transform-translate-n50 z-index-3"
						@click="play_video(item.id)"
					></button>
				</div>
				<div class="c-videos__details padding-12">
					<div
						class="c-videos__title font-size-18 font-weight-700 line-height-150"
					>
						{{ item.title }}
					</div>
				</div>
			</li>
		</ul>
		<div
			class="c-videos__load-more bp-1024:margin-t-12 text-align-center width-100"
		>
			<button
				class="c-videos__load-more-btn button --on-light display-inline-block reset"
			>
				Load More
			</button>
		</div>
		<modal-base v-model="modal_active">
			<template>
				<div style="width:100%;max-width:1024px;">
					<youtube
						fitParent
						:video-id="active_video_id"
						ref="youtube"
					></youtube>
				</div>
			</template>
		</modal-base>
	</div>
</template>

<script>
import ModalBase from '@/components/Common/ModalBase';

export default {
	name: 'YoutubeList',

	components: {
		ModalBase,
	},

	props: {
		data: {
			type: Array,
			required: true,
			default: () => [],
		},
	},

	data: () => ({
		modal_active: false,
		active_video_id: null,
	}),

	computed: {
		player() {
			return this.$refs.youtube.player;
		},
	},

	watch: {
		modal_active: function(val) {
			if (val === false) this.active_video_id = null;
		},
	},

	methods: {
		play_video(id) {
			this.active_video_id = id;
			this.modal_active = true;
			setTimeout(() => this.player.playVideo(), 250);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/_mixins';

.c-videos {
	//for naming
	&__header {
		border-bottom-style: dotted;
		&-title{
			font-family: mislab-std, sans-serif;
		}
	}
	&__item {
		&:hover,
		&:focus {
			.c-videos__image {
				transform: scale(1.1);
			}
			.c-videos__media::before {
				opacity: 0;
			}
		}
		&:last-child {
			margin-bottom: 0 !important;
		}
	}
	&__media {
		&::before {
			content: '';
			background: rgba($color: #000000, $alpha: 0.4);
			height: 100%;
			pointer-events: none;
			position: absolute;
			top: 0;
			left: 0;
			transition: 0.25s;
			width: 100%;
			z-index: 2;
		}
	}
	&__play-btn {
		@include play_button(75, 6, 48);
		position: absolute !important;
	}
}
</style>
